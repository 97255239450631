$(function() {
    function move_backgrounds() {
        windowPosition=$(window).scrollTop();
        $(".nasmo-parallax").each(function() {
            elementPosition = $(this).offset();
            if ((windowPosition+originalHeight-margins)>$(this).attr('data-originalTop')) {
                backY = (windowPosition-$(this).attr('data-originalTop')+margins-initialPosY)*0.2;
                $(this).css('background-position', 'center '+ backY +'px');
            }
        }); 
    }
    
    function init_parallax() {  
        margins = parseInt($('body').css('padding-top'))+parseInt($('body').css('margin-top'));
        originalHeight = $(window).outerHeight();
        initialPosY = (originalHeight*0.2);
        $(".nasmo-parallax").each(function() {
            var Offset = $(this).offset();
            var box=$(this);
            $(this).attr('data-originalTop',Offset.top);   
            box.css('background-repeat','no-repeat').css('background-size', 'cover').css('background-attachment','absolute');
        });
        move_backgrounds();
    }
    
    $(window).scroll(function(){
        move_backgrounds()
    })
    
    var margins, originalHeight, initialPosY;
    
    $(function () {
        init_parallax();
    });
    $( window ).resize(function() {
      init_parallax();
    });
});