// HEADER MASQUÉ PAR DÉFAUT S'AFFICHE AU SCROLL
$(window).scroll(function() {
    var value = jQuery(this).scrollTop();
    if ( value > 50 ) {
        $("#remonte").css('display','block').css( "opacity","0.8");
    } else {
        $("#remonte").css( "opacity","0").css('display','none');
    }
    
});
$('.remonte').on('click',function (event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: 0
    }, 500);
});

$('.prixMinMax').on('keyup',function() {
	if ($(this).val()>''){
		console.log($(this).val());
		var verif = /^([0-9]+)$/ ;
		if (!verif.test($(this).val())) {
			$('#alertPrix').html('Veuillez ne saisir que des chiffres, sans point ou virgule');
		}
	    else if  (($('#prix_min').val()>'') && ($('#prix_max').val()>'') && (parseInt($('#prix_min').val())>parseInt($('#prix_max').val()))) {
	        $('#alertPrix').html('Le montant minimum doit être inférieur au prix maximum !');
	    } else {$('#alertPrix').html('&nbsp;');}
	}
})

$(function() {        
    wow = new WOW(
      {
        animateClass: 'animated',
        offset:       100,
        callback:     function(box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
    $('[data-toggle="tooltip"]').tooltip();
    
    $('[data-toggle="popover"]').popover();
    
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });
    var changed=false;
    $(".selectpicker").on("changed.bs.select", function(e, clickedIndex) {
        if (changed==false) {
            changed=true;
            var clicked = $(this).find('option').eq(clickedIndex).val();
            var index = $(this).val().indexOf('0');
            if (clicked == 0) {
                $(this).selectpicker('val', '0');
            } else {
                if (index == 0) {$(this).selectpicker('val', $(this).val().slice(1));}
            }
            $(this).selectpicker('refresh');
        }
        changed = false;
    });
    
    
    $(".lightGallery").lightGallery({
        thumbnail:true,
        selector: '.selector'
    });
    
    $('table').each(function(){
       $(this).find('td').css('padding',$(this).attr('cellpadding')+'px');
       $(this).find('td').css('margin',$(this).attr('cellspacing')+'px');
    });

    $('[data-link]').on('click', function(e) {
        e.preventDefault();
        if ($(this).data('target')=="new") {
            var win = window.open($(this).data('link'), '_blank');
              win.focus();
        }
        else { location.href = $(this).data('link'); }
        
    });
    
    $('[data-api_link]').on('click', function(e) {
        e.preventDefault();
        alert ('click');
        location.href = $(this).data('api_link');
    });
    
    $('.same-height').matchHeight();
    
    $('#hideseeksearch').hideseek({
        list: '.hideseek-list',
        highlight: true,
		ignore_accents: true
    });
    
    $('.grid').colcade({
        columns: '.epimmo-grid-col',
        items: '.epimmo-grid-item'
    });
    $( window ).resize(function() {
        if ($('body').height() < $(window).height()) {$('#footer-new').addClass('footer-bottom');}
    });
    if ($('body').height() < $(window).height()) {$('#footer-new').addClass('footer-bottom');}
    
    
    
});